#footer {
  position: fixed;
  display: flex;
  bottom: 0px;
  background-color: #54b3bd;
  margin-left: -28px;

  align-items: center;

  padding: 15px;

  height: 75px;
  width: 100%;

  z-index: 2;
}

a {
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 15px;
}

.icons {
  margin-left: 150px;
}

.contact-info {
  margin-left: 900px;
  width: fit-content;
  align-items: center;
}

@media screen and (max-width: 600px) and (min-width: 300px) {
  .icons {
    display: inline;
    margin: 0;
    width: fit-content;
  }

  .contact-info {
    margin-left: 0;
    margin-right: 2rem;
  }
}

@media screen and (max-width: 1200px) and (min-width: 601px) {
  .icons {
    width: fit-content;
    display: inline;
    margin: 0;
  }

  .contact-info {
    margin-left: 500px;
  }
}
