#profile {
  float: right;
  width: fit-content;

  margin: 2rem;
  margin-right: 10rem;
}

img {
  border-radius: 10%;
}

.profile-link {
  position: relative;
  left: 30%;
  bottom: 31rem;

  font-size: 34px;
  z-index: 4;
}

.profile-img {
  width: 400px;
  height: 475px;
}

@media screen and (max-width: 600px) and (min-width: 300px) {
  #profile {
    position: relative;
    margin: 20px;
    left: 3.8rem;
    top: 15px;
    z-index: 1;
  }

  .profile-link {
    position: absolute;
    font-size: 28px;
  }

  .profile-img {
    width: 370px;
    height: 450px;
    left: 0;
  }
}
