@import url('https://fonts.googleapis.com/css2?family=Jura:wght@300..700&display=swap');

body {
  background-color: #0c5e44;
  color: #ffffff;
  padding: 20px;
  padding-bottom: 150px;

  font-family: Jura;
}

a {
  text-decoration: none;
  color: inherit;
}

ul li {
  list-style-type: none;
  margin: 2rem;
}
