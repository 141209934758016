@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideBack {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}

#menu {
  background-color: #54b3bd;
  position: absolute;
  width: fit-content;

  border-radius: 5%;
  font-size: 34px;
  font-weight: bold;
  text-decoration: none;

  margin: 4rem;
  padding: 1rem;

  z-index: 2;
}

#show-menu-btn {
  position: absolute;
  top: 3rem;
  left: 4rem;
  z-index: 3;
}

.show-menu {
  animation: slideIn 0.5s ease forwards;
}

.hide-menu {
  animation: slideBack 0.5s ease forwards;
}

@media screen and (max-width: 600px) and (min-width: 300px) {
  #menu {
    left: 5px;
    margin: 2rem;
  }
}
