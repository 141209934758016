#home,
#education,
#career,
#projects,
#life {
  position: relative;
  left: 10%;
  top: 5rem;
  width: fit-content;
  height: 500px;
}

#home {
  font-size: 36px;
}

@media screen and (max-width: 600px) and (min-width: 300px) {
  #home,
  #education,
  #career,
  #projects,
  #life {
    position: relative;
    height: fit-content;
    max-width: 300px;
    margin-left: -3rem;
    margin-bottom: 150px;

    white-space: normal;
    overflow-wrap: break-word;
    word-break: break-word;
  }
}

@media screen and (max-width: 1200px) and (min-width: 601px) {
  #home,
  #education,
  #career,
  #projects,
  #life {
    position: relative;
    height: fit-content;
    max-width: 600px;
    top: 50px;
    left: 10rem;

    white-space: normal;
    overflow-wrap: break-word;
    word-break: break-word;
  }
  #education {
    word-wrap: break-word;
  }
}
